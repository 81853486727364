<template>
    <q-toolbar class="q-gutter-sm q-my-xs">
        <div style="width: 6px; height: 29px; background: #3d7fff"></div>
        <div class="text-h6 text-weight-bold">{{ title }}</div>
        <q-space />
        <slot name="center"></slot>
        <q-space />
        <slot name="right"></slot>
    </q-toolbar>
</template>

<script>
export default {
    name:'pageTitle',
    props: {
        title: {
            default: 'title',
            type: String
        }
    }
}
</script>

<style>
</style>